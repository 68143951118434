import React, { useEffect, useState } from 'react';
import { Easing, Tween, autoPlay } from 'es6-tween';

import ReactFlow, {
  ReactFlowProvider,
  Controls,
  isNode,
} from 'react-flow-renderer';
import dagre from 'dagre';

import './index.css'

import QuestionNode from './components/QuestionNode/QuestionNode';
import ButtonEdge from './components/ButtonEdge/ButtonEdge';
// import initialElements from './initial-elements';
// import Sidebar from './components/Debug/Sidebar';

// 入场动画
autoPlay(true);
const TRANSITION_TIME = 600;
const EASING = Easing.Quadratic.Out;

// 自定义Node
const nodeTypes = {
  questionNode: QuestionNode,
};

// 自定义Edge
const edgeTypes = {
  buttonEdge: ButtonEdge,
}

const nodeWidth = 224;
const nodeHeight = 112;

export default () => {
  const [elements, setElements] = useState([]);
  const [renderFlow, setRenderFlow] = useState(false)
  
  useEffect(() => {
    function fetchData(questions) {
      debugger;
      const position = { x: 0, y: 0 };
      const edgeType = 'buttonEdge';
      const nodeType = 'questionNode';

      // TODO: 待测试
      // 手动插入正常完成，id: '-1'为正常完成
      questions.push({
        id: '-1',
        question_index: "-1",
        title: '正常完成',
        stem: '',
      })
      // 手动插入提前终止，id: '-2'为提前终止
      questions.push({
        id: '-2',
        question_index: "-2",
        title: '提前终止',
        stem: '',
      })
      // id: '-2' 为甄别结束题
      // 判断是否添加甄别结束题
      // const hasZB = questions.find(q => {
      //   return q.logics && q.logics.some(l => l.skip_question_id === -2)
      // })
      // console.log('hasZB', hasZB)
      // if (hasZB) {
      //   questions.push({
      //     id: '-2',
      //     title: '甄别结束',
      //     stem: '',
      //   })
      // }


      // step2: 生成Node
      const nodes = []
      questions.forEach(q => {
        nodes.push({
          id: String(q.question_index),
          type: nodeType,
          data: { ...q },
          position,
        })
      })
      // 问卷结束
      // nodes.push({
      //   id: '0',
      //   type: nodeType,
      //   data: { title: '结束', stem: '' },
      //   position,
      // })

      // step3: 生成Edge
      const edges = []
      questions.forEach((q, index, array) => {
        const nextQ = array[index + 1] ? array[index + 1] : null
        if (nextQ) {
          // 不能把正常题和提前终止相连接
          if (q.id === '-2' || nextQ.id === '-2') return;
          edges.push({
            id: `e${q.question_index}${nextQ.question_index}`,
            source: String(q.question_index),
            target: String(nextQ.question_index),
            type: edgeType,
            arrowHeadType: 'arrow',
            data: { visible: false, ...q },
          })
        }
        // 如果有逻辑，生产对应逻辑edge
        if (q.logics && q.logics.length !== 0) {
          q.logics.forEach(logic => {
            const edgeID = `e${q.question_index}${logic.skip_question_index}`
            // 可能有多个edge指向一个node
            if (edges.find(i => i.id === edgeID)) return;

            edges.push({
              id: `e${q.question_index}${logic.skip_question_index}`,
              source: String(q.question_index),
              target: String(logic.skip_question_index),
              type: edgeType,
              arrowHeadType: 'arrow',
              data: { visible: false, ...q },
            })
          })
        }
      })


      console.log(nodes, '---nodes----')
      console.log(edges, '---edges----')

      // step4: 生成所有Node布局position
      const dagreGraph = new dagre.graphlib.Graph();
      dagreGraph.setDefaultEdgeLabel(() => ({}));

      const getLayoutedElements = (elements, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction, ranksep: 150 });

        console.log(elements, 'getLayoutedElements')

        elements.forEach((el) => {
          if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
          } else {
            dagreGraph.setEdge(el.source, el.target);
          }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
          if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = isHorizontal ? 'left' : 'top';
            el.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            // el.position = {
            //   x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
            //   y: nodeWithPosition.y - nodeHeight / 2,
            // };
            el.position = {
              x: nodeWithPosition.x - nodeWidth / 2,
              y: nodeWithPosition.y,
            };
          }

          return el;
        });
      };

      const layoutedElements = getLayoutedElements([...nodes, ...edges]);
      console.log('---layoutedElements---', layoutedElements)

      setElements(layoutedElements)
      setRenderFlow(true)
    }
    // 监听父级窗口发送的题型列表
    window.addEventListener('message', (data) => {
      if (data.data && data.data.length > 0) {
        const info = JSON.parse(data.data);
        // 初始化数据
        if (info.list) {
          fetchData(info.list)
        }
      }
    }, false)
  }, [])

  const onLoad = (reactFlowInstance) => {
    console.log('onLoad ----- onLoad')

    const originZoom = 1
    const toZoom = 1.2

    // 重新去计算位置数据 version: 1
    // let setX = document.body.clientWidth / 2 - nodeWidth / 2


    // 获取第一个元素的位置 version:2
    const firstNode = elements[0]
    const { x: fx } = firstNode.position
    const calcX = -fx * toZoom + document.body.clientWidth / 2 - nodeWidth * toZoom / 2
    console.log('calcX', calcX)

    // 为了动画效果先调整y: -100, zoom: 1.3
    // reactFlowInstance.setTransform({ x: setX, y: -100, zoom: toZoom });
    reactFlowInstance.setTransform({ x: calcX, y: -100, zoom: toZoom });

    // 动画入场效果
    new Tween({ zoom: toZoom })
      .to({ zoom: originZoom }, TRANSITION_TIME)
      .easing(EASING)
      .on('update', ({ zoom }) => reactFlowInstance.zoomTo(zoom))
      .start();

  };

  const handleVisibleChange = (e) => {
    console.log('handleVisibleChange', e)

    // 关闭所有逻辑弹窗 - TODO:截流
    setElements((els) =>
      els.map((e) => {
        e.data.visible = false;
        return e;
      })
    );
  }

  return (
    <ReactFlowProvider>
      {/* <Sidebar /> */}
      <div className="cnpIbj" style={{ height: '100vh', background: '#FFFFFF' }}>

        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0, position: 'absolute' }}>
          <defs>
            <marker id="typeform_vld_marker_arrow" markerWidth="6.4" markerHeight="11.2" viewBox="0 0 8 14" orient="auto" refX="6" refY="7" fill="#262627">
              <path d="M.707 3.121L3.586 6l.992.988L3.586 8 .707 10.879a2 2 0 000 2.828L7.414 7 .707.293a2 2 0 000 2.828z"></path>
            </marker>
          </defs>
        </svg>

        {/* {
          !id && <div className="center">数据获取失败!</div>
        } */}

        {renderFlow ? <ReactFlow
          elements={elements}
          onLoad={onLoad}
          minZoom={0.15}
          maxZoom={3.5}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onMoveStart={handleVisibleChange}
          onNodeDragStart={handleVisibleChange}
          markerEndId="typeform_vld_marker_arrow"
        >
          <Controls showInteractive={false} />
        </ReactFlow>
          : <div className="center">加载中...</div>
        }

      </div>
    </ReactFlowProvider>
  );
};