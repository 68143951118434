import { useStoreState } from 'react-flow-renderer';
import './LogicContent.css'
import Radio from './questions/Radio'
import CheckBox from './questions/CheckBox'
import Completion from './questions/Completion'
import Rate from './questions/Rate'
import MatrixRadio from './questions/MatrixRadio';
import MatrixCheckBox from './questions/MatrixCheckBox';
import MatrixRate from './questions/MatrixRate';
import MatrixCompletion from './questions/MatrixCompletion';
import Sort from './questions/Sort';
import ConstantSum from './questions/ConstantSum';
import Classify from './questions/Classify';
/**
 * 展示连线之间的逻辑判断条件弹框内容
 * @param {data} 题目的所有信息
 * @param {target} 连线目标源的的question_index
 * @returns 
 */
export default function ({ data, target }) {
  console.log('props data', data);

  console.log('target', target)

  const { nodes } = useStoreState((state) => state);
  console.log('nodes', nodes)

  // 获取当前edge的所有逻辑
  const currentLogics = data.logics.filter(l => String(l.skip_question_index) === target)

  console.log('currentLogics', currentLogics)


  const findQuestion = (questionIndex) => {
    debugger;
    return nodes.find(i => i.id === String(questionIndex))
  }
  const findQuesOption = (questionIndex, optionIndex) => {
    debugger
    const q = findQuestion(questionIndex);
    return q.data.options[0].find((opt) => opt.option_index === optionIndex);
  }
  const findQuesColOption = (questionIndex, optionIndex) => {
    debugger
    const q = findQuestion(questionIndex);
    return q.data.options[1].find((opt) => opt.cell_index === optionIndex);
  }
  // const findQuestionAnswer = (qid, aid) => {
  //   const q = findQuestion(qid)
  //   // console.log('--1--', q)
  //   // console.log(q.data)
  //   // console.log(q.data.question_list)
  //   // console.log(q.data.question_list[0])
  //   // console.log(q.data.question_list[0].options)
  //   const { options } = q.data.question_list.find(i => i.type == 0)
  //   return options.find(o => o.id == aid).option
  // }

  // const findQuestionAnswerJvZhen = (qid, row_option_id, col_option_id) => {
  //   const q = findQuestion(qid)
  //   const { options: row } = q.data.question_list.find(i => i.type == 0)
  //   const { options: col } = q.data.question_list.find(i => i.type == 1)
  //   return `${row.find(o => o.id == row_option_id).option}-${col.find(o => o.id == col_option_id).option}`
  // }

  /**
   * 选择 单选
   */
  // const RADIO_QUESTION = 1;

  /**
  * 选择 多选
  */
  // const CHECKBOX_QUESTION = 2;

  /**
  * 级联
  */
  // const CASCADE_QUESTION = 3;

  /**
  * 填空
  */
  // const TEXT_QUESTION = 4;

  /**
  * 排序
  */
  // const SORT_QUESTION = 16;

  /**
  * 恒定总和
  */
  // const CONSTANT_QUESTION = 17;


  /**
  * 图片单选
  */
  // const PICTURE_RADIO_QUESTION = 13;

  /**
  * 图片多选
  */
  // const PICTURE_RADIO_QUESTION = 14;

  /**
  * 分类
  */
  // const TYPE_QUESTION = 15;


  /**
  * 打分
  */
  // const SCORE_QUESTION = 5;

  /**
  * 矩阵填空
  */
  // const MATRIX_TEXT_QUESTION = 8;

  /**
  * 矩阵单选
  */
  // const MATRIX_RADIO_QUESTION = 9;

  /**
  * 矩阵多选
  */
  // const MATRIX_CHECKBOX_QUESTION = 10;

  /**
  * 矩阵打分
  */
  // const MATRIX_RATE_QUESTION = 11;
  return (
    <div style={{ width: 360 }}>
      <div class="Container-sc-__sc-1aileh0-0 QMsCZ">
        <div orientation="horizontal" class="Distribute-sc-__sc-1s2i8aq-0 hEcBFA">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.473 2.503" height="9.459" width="13.126">
            <path fill="currentColor" d="M3.43 1.197a.149.149 0 000-.21L2.481.038a.149.149 0 00-.21.21l.841.843-.841.842a.149.149 0 00.21.21zm-1.87.043h1.764V.943H1.56zM.298 2.503A1.262 1.262 0 011.56 1.24V.943A1.56 1.56 0 000 2.503h.298z"></path>
          </svg>
          <span class="Text-sc-__sc-1h7ebrz-0 efKJKA">逻辑跳转</span>
        </div>
      </div>
      <div class="sc-fznAgC fCIktI">
        {currentLogics.map(logic => {
          return (
            <div class="cGgyBl">
              <div orientation="vertical" class="bUjhUF">
                <div class="GXatJ">
                  <div class="jfXRgS">
                    <div data-qa="logic-display-condition-prefix" class="ecKskL">If</div>
                    <div width="remaining" class="eFFLv">
                      <div class="kyojiS">
                        {logic.logic.map(lj => {
                          debugger
                          const qs = findQuestion(lj.question_index)

                          // 没找到题目
                          if (!qs) return (
                            <div></div>
                            // <div>没找到{lj.question_id}的题目</div>
                          )

                          return (
                            <>
                              <div>
                                <span data-qa="logic-display-chain-op">{lj.logic !== 'if' && lj.logic} </span>
                                <span data-qa="logic-display-source-left">
                                  <strong>{qs.data.title}. </strong>
                                  <p dangerouslySetInnerHTML={{__html:qs.data.stem}}></p>
                                </span>
                              </div>
                              {(qs.question_type === 1 || qs.question_type === 13) && <Radio logic={lj} option={findQuesOption(lj.question_index, lj.option_index)}></Radio>}
                              {(qs.question_type === 2 || qs.question_type === 14) && <CheckBox logic={lj} option={findQuesOption(lj.question_index, lj.option_index)}></CheckBox>}
                              {qs.question_type === 4 && <Completion logic={lj}></Completion>}
                              {qs.question_type === 5 && <Rate logic={lj} option={findQuesOption(lj.question_index, lj.option_index)}></Rate>}
                              {qs.question_type === 8 && <MatrixCompletion logic={lj} option={findQuesOption(lj.question_index, lj.row_index)} colOption={findQuesColOption(lj.question_index, lj.cell_index)}></MatrixCompletion>}
                              {qs.question_type === 9 && <MatrixRadio logic={lj} option={findQuesOption(lj.question_index, lj.row_index)} colOption={findQuesColOption(lj.question_index, lj.cell_index)}></MatrixRadio>}
                              {qs.question_type === 10 && <MatrixCheckBox logic={lj} option={findQuesOption(lj.question_index, lj.row_index)} colOption={findQuesColOption(lj.question_index, lj.cell_index)}></MatrixCheckBox>}
                              {qs.question_type === 11 && <MatrixRate logic={lj} option={findQuesOption(lj.question_index, lj.row_index)} colOption={findQuesColOption(lj.question_index, lj.cell_index)}></MatrixRate>}
                              {qs.question_type === 16 && <Sort logic={lj} option={findQuesOption(lj.question_index, lj.option_index)}></Sort>}
                              {qs.question_type === 15 && <Classify logic={lj} option={findQuesOption(lj.question_index, lj.row_index)} colOption={findQuesColOption(lj.question_index, lj.cell_index)}></Classify>}
                              {qs.question_type === 17 && <ConstantSum logic={lj} option={findQuesOption(lj.question_index, lj.option_index)}></ConstantSum>}
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div class="jfXRgS">
                    <div data-qa="logic-display-details-prefix" class="ecKskL">then</div>
                    <div width="remaining" class="eFFLv">
                      <div>
                        <span data-qa="logic-display-details-action">跳转到</span>
                        <span data-qa="logic-display-details-source">
                          <strong>{findQuestion(logic.skip_question_index).data.title}. </strong>
                          {findQuestion(logic.skip_question_index).data.stem}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* 逻辑编辑按钮 */}
      {/* <div class="Container-sc-__sc-1aileh0-0 eUnmST">
        <button type="button" class="ButtonRoot-sc-__sc-1vu0deq-0 klxZek">
          <div orientation="horizontal" class="Distribute-sc-__sc-1s2i8aq-0 YOXft"><span font-family="sans" class="Text-sc-__sc-1h7ebrz-0 djRoaw">Edit</span></div>
        </button>
      </div> */}
    </div>
  );
}