import React from 'react';
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
  useStoreState,
  useStoreActions,
} from 'react-flow-renderer';

import { Popover } from 'antd';

import LogicIconScale from '../CommonCSSScale'
import { getCSSScale, getLayout } from '../../helper/helper'
import './ButtonEdge.css';
import LogicContent from '../LogicContent/LogicContent'

export default function CustomEdge({
  id,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {stroke: "rgb(38, 38, 39)", strokeWidth: 1, fill: "none" },
  data,
  arrowHeadType,
  markerEndId,
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [, , reactFlowScale] = useStoreState((store) => store.transform);

  const cssScale = getCSSScale(reactFlowScale)
  const { showTitle } = getLayout(reactFlowScale)

  const { nodes,edges } = useStoreState((state) => state);
  const setElements = useStoreActions(actions => actions.setElements)
  
  // 测试
  // const cssScale = 1
  // const showTitle = false
  // 连线之间的按钮点击回调，打开详细的逻辑跳转判断条件
  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    edges.forEach(e => {
      e.data.visible = false
      if (e.id == id) {
        e.data.visible = !e.data.visible
      }
    })

    setElements([...nodes, ...edges])
    // alert(`remove ${id}`);

  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />

      {/* 如果有逻辑跳转，显示此按钮 */}
      { showTitle && 
        data.logics &&
        data.logics.length > 0 && 
        data.logics.filter(l => String(l.skip_question_index) === target).length > 0 &&
      <Popover 
      overlayStyle={{ padding: 0}}
      overlayInnerStyle={{ padding: 0}}
      overlayClassName="custom-popover"
      placement="left"
      content={<LogicContent target={target} data={data}/>} 
      trigger="click"
      visible={data.visible}>
        <g
          onClick={(event) => onEdgeClick(event, id)}
          role="button" 
          tabIndex="0" 
          data-qa="logic-jump-edge-button" 
          transform={`translate(${edgeCenterX}, ${edgeCenterY})`} 
          className="cJveKj"
        >
          <LogicIconScale scale={cssScale}>
            <g transform="translate(-5.5, -5.5)">
              <circle cx="5.5" cy="5.5" r="10" className="cSgtvy"></circle>
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" color="">
                <circle cx="5.5" cy="5.5" r="8" fill="transparent"></circle>
                <path fill="currentColor" d="M9.27497 7.55658c-.33409.00018-.66088.09777-.94036.28083-.27947.18306-.4995.44365-.63313.74985H5.35155c-.30164.00002-.5949-.09921-.83456-.28236-.23965-.18316-.4124-.44008-.49157-.73114L2.78861 3.04568c.21632-.17081.38836-.39117.50159-.64246h4.41128c.15786.3617.43544.65801.78608.83912.35063.1811.75294.23595 1.13926.15532.38628-.08063.73308-.29182.98198-.59806.249-.30624.3848-.68885.3848-1.08349 0-.39465-.1358-.777256-.3848-1.083495-.2489-.306239-.5957-.517429-.98198-.5980587C9.2405-.0460735 8.83819.00877499 8.48756.189879c-.35064.181104-.62822.477417-.78608.839111H3.2902C3.17149.754064 2.98297.514944 2.74333.33535 2.5037.155757 2.22128.0419287 1.92408.00515697 1.62688-.0316148 1.32523.00994713 1.04905.12572.772874.241493.531754.427454.349615.665161.167476.902867.0506461 1.18406.0107074 1.48085c-.0399386.29679-.00159613.59886.1112256.87626.112821.2774.296203.52048.531954.70515.23575.18466.515683.30448.812023.34759l1.23337 4.52808c.15836.58211.50384 1.09596.98316 1.46227.47932.36632 1.06584.56476 1.66911.56472h2.34993c.12442.28338.32311.52788.57505.70768.25194.1798.54778.2882.85624.3137.30846.0255.61808-.0328.89613-.1687.2781-.136.5142-.3446.6835-.6037.1693-.25908.2654-.55912.2783-.86837.0128-.30925-.0582-.61622-.2054-.88845-.1473-.27224-.3654-.49963-.6312-.65814-.26588-.15851-.56961-.24225-.87913-.24236z"></path>
              </svg>
              <rect fill="none" rx="4" ry="4" x="-2" y="-2" width="15" height="15" className="sc-fznMnq biVOqy"></rect>
            </g>
          </LogicIconScale>
        </g>
        </Popover>}
    </>
  );
}
