export default function ({ logic, option, colOption }) {
  return (
    <div>
      <span data-qa="logic-display-operator">{option.option} </span>
      <span data-qa="logic-display-source-right">
        {colOption.option}
      </span>
      <span data-qa="logic-display-source-right">
        {logic.is_select ? "被选中" : "未被选中"}
      </span>
    </div>
  )
}