export default function ({ logic, option, colOption }) {
  return (
    <div>
      <span data-qa="logic-display-operator">{option.option} </span>
      <span data-qa="logic-display-source-right">
        {logic.operator}
      </span>
      <span data-qa="logic-display-source-right">
        {colOption.option}
      </span>
    </div>
  )
}