import React from 'react';
import './App.css';

import Home from './pages/Home/index'

// window.addEventListener('message',(e) => {
//   console.log(e);
// })
function App() {
  return (
    <Home />
  );
}

export default App;
