export const LayoutTypes = {
  details: "details",
  outline: "outline",
  post_summary: "post_summary",
  pre_outline: "pre_outline",
  summary: "summary",
}

export const layoutBreakpoints = [
  {layout: 'details', start: 100},
  {layout: 'summary', start: 1.2413793103448276},
  {layout: 'post_summary', start: 0.7777777777777778},
  {layout: 'pre_outline', start: 0.562358276643991},
  {layout: 'outline', start: 0.4591836734693878}
]

export const layouts = {
	"outline": {
		"type": "outline",
		"zoom": 0.35714285714285715,
		"scale": 2.8,
		"padding": 8,
		"showTitle": false,
		"showDetails": false,
		"showEdgeLogicIcon": false,
		"linesToShow": 1
	},
	"pre_outline": {
		"type": "pre_outline",
		"zoom": 0.5102040816326532,
		"scale": 1.9599999999999997,
		"padding": 16,
		"showTitle": false,
		"showDetails": false,
		"showEdgeLogicIcon": false,
		"linesToShow": 1
	},
	"post_summary": {
		"type": "post_summary",
		"zoom": 0.6666666666666666,
		"scale": 1.5,
		"padding": 16,
		"showTitle": true,
		"showDetails": false,
		"showEdgeLogicIcon": true,
		"linesToShow": 3
	},
	"summary": {
		"type": "summary",
		"zoom": 1,
		"scale": 1,
		"padding": 16,
		"showTitle": true,
		"showDetails": false,
		"showEdgeLogicIcon": true,
		"linesToShow": 3
	},
	"details": {
		"type": "details",
		"zoom": 1.7241379310344829,
		"scale": 0.58,
		"padding": 16,
		"showTitle": true,
		"showDetails": true,
		"showEdgeLogicIcon": true,
		"linesToShow": 6
	}
}

// 放大缩小变化的最大最小值
export const minZoom = 0.35714285714285715
export const maxZoom = 1.7241379310344829

export const getCSSScale = (reactFlowScale) => {
  let scale;

  scale = Math.max(reactFlowScale, minZoom)
  scale = Math.min(scale, maxZoom)

  return 1 / scale;
}

export const getLayoutName = (reactFlowScale) => {
  for (let t = 0; t < layoutBreakpoints.length - 1; t++) {
    if (reactFlowScale <= layoutBreakpoints[t].start && reactFlowScale > layoutBreakpoints[t + 1].start) {
      return layoutBreakpoints[t].layout;
    }
  }
  return LayoutTypes.outline
}

export const getLayout = (reactFlowScale) => layouts[getLayoutName(reactFlowScale)]